import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function SearchIcon(props: SvgIconProps) {
    return (
        <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
            <path
                d="M19.7647 16.8333L23 20M1 1H21.7059M1 8.6H6.17647M1 16.2H6.17647M10.0588 12.4C10.0588 13.7438 10.6042 15.0325 11.575 15.9827C12.5458 16.9329 13.8624 17.4667 15.2353 17.4667C16.6082 17.4667 17.9248 16.9329 18.8956 15.9827C19.8664 15.0325 20.4118 13.7438 20.4118 12.4C20.4118 11.0562 19.8664 9.76751 18.8956 8.81733C17.9248 7.86714 16.6082 7.33333 15.2353 7.33333C13.8624 7.33333 12.5458 7.86714 11.575 8.81733C10.6042 9.76751 10.0588 11.0562 10.0588 12.4Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
}

export default SearchIcon;
