import BetTypesFilterIcon from "@/components/Icons/BetTypesFilterIcon";
import BookMarkIcon from "@/components/Icons/BookMarkIcon";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import LeagueFilterIcon from "@/components/Icons/LeagueFilterIcon";
import ROIFilterIcon from "@/components/Icons/ROIFilterIcon";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import SearchFilterIcon from "@/components/Icons/SearchFilterIcon";
import OddTypeIcon from "@/components/Icons/OddTypeIcon";

export enum FilterEnum {
  SPORTSBOOKS = "SPORTSBOOKS",
  BET_TYPES = "BET_TYPES",
  DATE_TIME = "DATE_TIME",
  LEAGUES = "LEAGUES",
  ROI = "ROI",
  ALERTS = "ALERTS",

  // Odds Type
  ODDS = "ODDS",

  //  EV Page Filters
  FORMULA_CREATOR = "FORMULA_CREATOR",
  EV_CONTROLS = "EV_CONTROLS",

  // Odds Page Filters
  CONTROLS = "CONTROLS",
  AVERAGE_FORMULA = "AVERAGE_FORMULA",

  // Only used for advance filter
  BET_TYPES_GROUP = "BET_TYPES_GROUP",
  SPORTS = "SPORTS",

  SPORTSBOOKS_RESTRICTED = "SPORTSBOOKS_RESTRICTED",
  LEAGUES_RESTRICTED = "LEAGUES_RESTRICTED",
  SPORTS_RESTRICTED = "SPORTS_RESTRICTED",

  BLOCKED_BETS = "BLOCKED_BETS",

  SEARCH_FILTER = "SEARCH_FILTER",
  EDIT_FILTERS = "EDIT_FILTERS",

  FILTER_ASSISTANT = "FILTER_ASSISTANT",
}

export type FilterListType = {
  key: FilterEnum;
  name: string;
  icon: React.JSX.Element;
};

export const ALL_PAGE_FILTER: FilterListType[] = [
  {
    key: FilterEnum.SPORTSBOOKS,
    name: "sportsbooks",
    icon: <BookMarkIcon />,
  },
  {
    key: FilterEnum.BET_TYPES,
    name: "bet_types",
    icon: <BetTypesFilterIcon />,
  },
  {
    key: FilterEnum.DATE_TIME,
    name: "date_time",
    icon: <CalendarTodayOutlinedIcon />,
  },
  {
    key: FilterEnum.LEAGUES,
    name: "leagues",
    icon: <LeagueFilterIcon />,
  },
  {
    key: FilterEnum.ROI,
    name: "roi",
    icon: <ROIFilterIcon />,
  },
  {
    key: FilterEnum.ALERTS,
    name: "alerts",
    icon: <WarningAmberRoundedIcon />,
  },
  {
    key: FilterEnum.SEARCH_FILTER,
    name: "block_exclude",
    icon: <SearchFilterIcon sx={{ mt: 0.5 }} />,
  },
  {
    key: FilterEnum.ODDS,
    name: "odds_type",
    icon: <OddTypeIcon />,
  }
];
