import { useCallback } from 'react'
import { CommonFilterType } from '@/components/Common/Filters/commonFilterType';
import { isNil } from 'lodash';
import { useUser } from '../UserContext';

function useSelectedFilterHasRestrictions() {
  const { restrictions: userRestrictions } = useUser();

  const isCurrFilterHasRestriction = useCallback((currSetting: CommonFilterType) => {
    if (!isNil(userRestrictions.rOILimit) && currSetting?.maxRoi && currSetting?.maxRoi > userRestrictions.rOILimit!) {
      return true;
    }
    if (!isNil(userRestrictions.maxResult) && currSetting?.take && currSetting?.take > userRestrictions.maxResult!) {
      return true;
    }
    if (
      userRestrictions.leagueLimit &&
      currSetting?.leagues?.length &&
      currSetting?.leagues?.length > userRestrictions.leagueLimit
    ) {
      return true;
    }
    if (
      userRestrictions.sportbookLimit &&
      currSetting?.sportsBook?.length &&
      currSetting?.sportsBook?.length > userRestrictions.sportbookLimit
    ) {
      return true;
    }
    return false;
  }, [userRestrictions.leagueLimit, userRestrictions.maxResult, userRestrictions.rOILimit, userRestrictions.sportbookLimit]);
  return isCurrFilterHasRestriction;
}

export default useSelectedFilterHasRestrictions