import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function ROIFilterIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M22.59 2.952 2.954 22.59a1.092 1.092 0 1 1 -1.544 -1.544L21.048 1.41a1.091 1.091 0 1 1 1.543 1.542M1.438 8.381a4.908 4.908 0 1 1 6.943 -6.942 4.908 4.908 0 0 1 -6.943 6.942m0.744 -3.468a2.728 2.728 0 1 0 5.455 -0.005 2.728 2.728 0 0 0 -5.455 0.005M24 19.092a4.908 4.908 0 1 1 -9.816 -0.002 4.908 4.908 0 0 1 9.816 0.002m-2.182 0a2.728 2.728 0 1 0 -5.454 0 2.728 2.728 0 0 0 5.454 0" />
    </SvgIcon>
  );
}

export default ROIFilterIcon;
