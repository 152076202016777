import {
  KeyOfPages,
  getDefaultFilterValuesByPathKey,
  CommonFilterVariableType,
  CommonFilterType,
  hasPageRestrictionLimit,
} from "@/components/Common/Filters/commonFilterType";
import { InputFilterBaseRequestType } from "@/user/user-filters/InputFilterBaseRequestType";
import { CommonSubscriptionVariables } from "@/components/Common/CommonSubscriptionVariables";
import { PackageTypeEnum } from "@/user/common";
import { League } from "@/components/Common/Enums/LeagueEnum";
import { PartialRecord } from "@/utis/partialRecord";
import { Sports } from "@/components/Common/Enums/SportsEnum";
import isPackageTypeFree from "./isPackageTypeFree";

function removeNullValuesShallow<T extends Record<string, unknown>>(obj: T | undefined): T | undefined {
  if (!obj) return undefined;
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== null)) as T;
}

// TODO:
// After all the user conversion is done, we need to change the variables of betSite, evWeights, evFormulaWeight to match with the backend
// Also advance filter are curently saved in the json itself. Because even if a user de select advance filter we still nedd to store them
// In future have a field in advance filter as is active and then store all the advance filter in backend itself and tell ken to use only the active filter
// Update the frontned also so that it uses this list kind of formation instead of the json

export const parseUserFilterToCommonFilterType = (
  filters: InputFilterBaseRequestType[] | undefined,
  json: string | undefined,
  pathKey: KeyOfPages,
  packageType: PackageTypeEnum | undefined,
  globalSportsBookEnums: string[] | undefined,
  sportsLeagueMap: PartialRecord<Sports, League[]> | undefined,
  lastModifiedPackageTypeEnum?: PackageTypeEnum,
): CommonFilterType => {
  if (!filters || !json) return getDefaultFilterValuesByPathKey(pathKey);
  const commonFilterVariableType = JSON.parse(json) as CommonFilterVariableType;
  // all advance filter contain filter hash
  const mainFilter = removeNullValuesShallow<CommonSubscriptionVariables>(filters.find((filt) => !filt.requestHash)?.requestData);
  if (!mainFilter) return getDefaultFilterValuesByPathKey(pathKey);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    __typename,
    betSites,
    betMarketTypes,
    afterDateTime,
    beforeDateTime,
    expectedValueWeights,
    expectedValueFormulaWeight,
    ...restFilter
  } = mainFilter;
  const newCommonApiFilter: CommonFilterType = {
    ...restFilter,
    ...commonFilterVariableType,
  };

  newCommonApiFilter.sportsBook = betSites ?? undefined;
  newCommonApiFilter.betMarketInfo = betMarketTypes ?? undefined;
  if (afterDateTime) {
    newCommonApiFilter.startDateTime = afterDateTime * 1000;
  }
  if (beforeDateTime) {
    newCommonApiFilter.endDateTime = beforeDateTime * 1000;
  }
  if (expectedValueWeights) {
    newCommonApiFilter.evWeights = expectedValueWeights;
  }
  if (expectedValueFormulaWeight) {
    newCommonApiFilter.evFormulaWeight = expectedValueFormulaWeight;
  }

  const isPackageTypeFreeAndDataLimited = isPackageTypeFree(packageType) && hasPageRestrictionLimit(pathKey);
  if (isPackageTypeFreeAndDataLimited) {
    // order is important
    newCommonApiFilter.sportsBook = newCommonApiFilter.rOIRestrictedBetSites;
    newCommonApiFilter.rOIRestrictedBetSites = [];
    newCommonApiFilter.leagues = newCommonApiFilter.rOIRestrictedLeagues;
    newCommonApiFilter.rOIRestrictedLeagues = [];
  }

  // If user is converted to advance and he has data is the roi restricted section. Move his data to the main filter section.
  if (
    packageType === PackageTypeEnum.ADVANCED &&
    (mainFilter.rOIRestrictedBetSites === undefined || mainFilter.rOIRestrictedBetSites?.length) &&
    isPackageTypeFree(lastModifiedPackageTypeEnum) &&
    hasPageRestrictionLimit(pathKey)
  ) {
    newCommonApiFilter.sportsBook = mainFilter.rOIRestrictedBetSites;
    newCommonApiFilter.rOIRestrictedBetSites = [];
  }
  if (
    packageType === PackageTypeEnum.ADVANCED &&
    (mainFilter.rOIRestrictedLeagues === undefined || mainFilter.rOIRestrictedLeagues?.length) &&
    isPackageTypeFree(lastModifiedPackageTypeEnum) &&
    hasPageRestrictionLimit(pathKey)
  ) {
    newCommonApiFilter.leagues = mainFilter.rOIRestrictedLeagues;
    newCommonApiFilter.rOIRestrictedLeagues = [];
  }

  // Caclaute the data selected in the adavance filter and add them to main filter
  const advBetSites: string[] = [];
  const advLeagues: League[] = [];
  const advSports: Sports[] = [];
  const advBetMarketType: string[] = [];
  const advBetMarketTypeGroup: string[] = [];

  filters.forEach((filt) => {
    if (filt.betSite) {
      advBetSites.push(filt.betSite);
    }
    if (filt.league) {
      advLeagues.push(filt.league);
    }
    if (filt.sport) {
      advSports.push(filt.sport);
      if (sportsLeagueMap?.[filt.sport]) advLeagues.push(...sportsLeagueMap[filt.sport]!);
    }
    if (filt.betMarketType) {
      advBetMarketType.push(filt.betMarketType);
    }
    if (filt.betMarketTypeGroup) {
      advBetMarketTypeGroup.push(filt.betMarketTypeGroup);
    }
    if (filt.betMarketTypeGroup) {
      advBetMarketTypeGroup.push(filt.betMarketTypeGroup);
    }
  });

  if (advBetSites.length) {
    newCommonApiFilter.sportsBook = newCommonApiFilter.sportsBook?.concat(advBetSites);
  }

  if (advLeagues.length) {
    newCommonApiFilter.leagues = newCommonApiFilter.leagues?.concat(advLeagues);
  }

  if (advBetMarketType.length) {
    newCommonApiFilter.betMarketInfo = newCommonApiFilter.betMarketInfo?.concat(advBetMarketType);
  }

  if (advBetMarketTypeGroup.length) {
    newCommonApiFilter.betMarketTypeGroups = newCommonApiFilter.betMarketTypeGroups?.concat(advBetMarketTypeGroup);
  }

  // Remove sportsbook from the filter if it's de selected from the global sportsbook of user
  if (globalSportsBookEnums?.length) {
    newCommonApiFilter.sportsBook = newCommonApiFilter.sportsBook?.filter((sp) => globalSportsBookEnums.includes(sp));
    newCommonApiFilter.rOIRestrictedBetSites = newCommonApiFilter.rOIRestrictedBetSites?.filter((sp) =>
      globalSportsBookEnums.includes(sp),
    );
  }

  return {
    minRoi: newCommonApiFilter.minROI,
    maxRoi: newCommonApiFilter.maxROI,
    ...newCommonApiFilter,
  } as CommonFilterType;
};
