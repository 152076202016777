import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function BetTypesFilterIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={18} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.38 5.262v-.976C17.38 1.883 13.561 0 8.69 0 3.817 0 0 1.883 0 4.286V8.57c0 2.051 2.779 3.72 6.62 4.167v.976c0 2.403 3.818 4.286 8.69 4.286 4.873 0 8.69-1.883 8.69-4.286V9.43c0-2.023-2.703-3.71-6.62-4.167m5.792 4.167c0 1.655-3.159 3.428-7.862 3.428-.97.002-1.94-.082-2.896-.25v-.15c2.96-.676 4.965-2.142 4.965-3.886V6.125c3.333.41 5.793 1.796 5.793 3.304M9.951 11.956Q9.34 12 8.69 12q-.85-.002-1.628-.074h-.028q-.646-.06-1.24-.167V8.336c.957.159 1.926.237 2.896.235a17.5 17.5 0 0 0 2.896-.235v3.42q-.797.141-1.605.198zm6.6-5.815v2.43c0 1.166-1.568 2.39-4.137 3.012V8.172c1.824-.417 3.284-1.134 4.138-2.03M8.69.857c4.702 0 7.862 1.773 7.862 3.429s-3.16 3.428-7.862 3.428C3.987 7.714.828 5.941.828 4.286S3.987.857 8.69.857M.828 8.571v-2.43c.853.897 2.314 1.614 4.138 2.036v3.412C2.396 10.96.828 9.737.828 8.57m6.62 5.143v-.9a18 18 0 0 0 2.483.001q.811.306 1.655.496v3.415c-2.57-.622-4.138-1.846-4.138-3.012m4.966 3.185v-3.424c.957.16 1.926.24 2.896.24a17.5 17.5 0 0 0 2.897-.236v3.42a17.3 17.3 0 0 1-5.793 0m10.758-3.185c0 1.166-1.568 2.39-4.137 3.012v-3.411c1.823-.417 3.284-1.134 4.137-2.036z" />
    </SvgIcon>
  );
}

export default BetTypesFilterIcon;
