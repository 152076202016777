import { Box, Modal, ButtonBase, Typography, styled } from '@mui/material';
import React from 'react';
import { useUserSettingsContextSelector } from '../user-settings/UserSettingsContext';

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: inline;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.modal.background};
    backdrop-filter: blur(4px);
    border-radius: 30px;
    border: 1px solid ${theme.palette.warning.light};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(4)};
    height: max-content;
    margin: auto;
    max-width: 1200px;
  `,
);

type SelectedFilterRestrictionWarningModelProps = {
  openWarningFilter: boolean;
  setOpenWarningFilter: (val: boolean) => void;
}

function SelectedFilterRestrictionWarningModel({ openWarningFilter, setOpenWarningFilter }: SelectedFilterRestrictionWarningModelProps) {

  const openLoginPopup = useUserSettingsContextSelector((ctx) => ctx.openLoginPopup);

  const handleCloseWarningFilter = () => {
    setOpenWarningFilter(false);
  };

  return (
    <Modal open={openWarningFilter} onClose={handleCloseWarningFilter} sx={{ display: "flex" }}>
      <StyledBox>
        Oops! It seems like the values you&apos;ve selected for your current filter exceed your limitations. To continue
        refining your search and remove those restrictions, please
        <ButtonBase
          onClick={() => openLoginPopup()}
          sx={{
            ":hover": { textDecoration: "underline" },
            mx: "4px",
            mb: "4px",
          }}
        >
          <Typography fontWeight="bold" sx={{ textDecoration: "underline" }}>
            Login / Signup in to your account.
          </Typography>
        </ButtonBase>
      </StyledBox>
    </Modal>
  )
}

export default SelectedFilterRestrictionWarningModel