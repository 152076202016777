import { CommonFilterType, getDefaultFilterValuesByPathKey, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { useCallback } from "react";
import { UserFiltersType } from "@/user/user-filters/common";

export const parseJsonToCommonFilterType = (value: string | undefined, pathKey: KeyOfPages) => {
  if (value) {
    try {
      const data = JSON.parse(value) as CommonFilterType;
      return data || getDefaultFilterValuesByPathKey(pathKey);
    } catch {
      return getDefaultFilterValuesByPathKey(pathKey);
    }
  }
  return getDefaultFilterValuesByPathKey(pathKey);
}

export const useGetUserFilterValue = () => {
  const getUserFilterValues = useCallback(
    (userFilters: UserFiltersType[] | undefined, selectedFilterId: string | undefined, defaultFilterId: string | undefined) => {
      const selectedFilter = userFilters?.find(filter => filter.id === (selectedFilterId || defaultFilterId));
      return selectedFilter?.commonFilter;
    },
    []
  );

  return getUserFilterValues;
};

