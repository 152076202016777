import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { debounce } from '@mui/material';
import { loadState, saveState } from '@/utis/localStorage';
import { createContext, useContextSelector } from "use-context-selector";
import * as Sentry from "@sentry/nextjs";
import { CommonFilterType, getDefaultFilterValuesByPathKey, KeyOfPages } from '@/components/Common/Filters/commonFilterType';
import useGetPathKey from '@/hooks/useGetPathKey';
import { v4 as uuidv4 } from 'uuid';
import { GetApiVariablesFromFiltersType } from '@/hooks/useGetApiVariablesFromFilters.common';
import ErrorBoundaryPopup from '@/components/ErrorBoundary/ErrorBoundaryPopup';
import useParseUserFilterToCommonFilterType from '@/hooks/useParseUserFilterToCommonFilterType';
import { useUser } from '../UserContext';
import { AddUserFilter, AddUserFilterVariables, ADD_USER_FILTER, EDIT_USER_FILTERS, EditUserFilters, EditUserFiltersVariables, InputCreatePickUserFilterType, InputEditPickUserFilterType, REMOVE_USER_FILTER, RemoveUserFilter, RemoveUserFilterVariables, PickUserFilterType, GET_USER_FILTERS, GetUserFilters, GetUserFiltersVariables, mapPathKeyToToolEnum, mapToolEnumKeyToPathKey, UserFiltersType, ToolEnumType } from './common';
import SelectedFilterRestrictionWarningModel from './SelectedFilterRestrictionWarningModel';
import useSelectedFilterHasRestrictions from './useSelectedFilterHasRestrictions';
import { useUserSettingsContextSelector } from '../user-settings/UserSettingsContext';

const KEY = "USER_FILTERS";

type UserFiltersContextValue = {
  userFilterFetched: boolean;
  addUserFilter: ((filter: InputCreatePickUserFilterType, commonFilterType: CommonFilterType, setSelected?: boolean) => void);
  editUserFilter: ((filters: InputEditPickUserFilterType[], toolEnum: ToolEnumType) => void);
  removeUserFilter: ((filterId: string) => void);
  updatePageChange: (newPathKey: KeyOfPages) => void;
  userFilterLoading: boolean;
  userFilters: UserFiltersType[] | undefined;
  selectedFilterId: string | undefined
  setSelectedFilterId: (selectedFilterId: string) => void;
  setDefaultFilterId: () => void;
  resetFilterToDefault: (getApiVariablesFromFilters: GetApiVariablesFromFiltersType, filterId?: string) => void
  defaultFilterId: string | undefined
  fetchUserFilters: (newPathKey: KeyOfPages) => Promise<UserFiltersType[] | undefined>
  setUserFilters: React.Dispatch<React.SetStateAction<PickUserFilterType[] | undefined>>
}

const UserFiltersContext = createContext<UserFiltersContextValue>(undefined!);

export function useUserFiltersContextSelector<Selected>(selector: (value: UserFiltersContextValue) => Selected) {
  return useContextSelector(UserFiltersContext, selector);
}

// const parseUserFilterForJson = (userFilters: PickUserFilterType[] | undefined) => userFilters?.map((userFilt) => ({ ...userFilt, commonFilter: JSON.parse(userFilt.json) as CommonFilterType }))

type UserFiltersProviderProps = {
  children: React.ReactNode;
};

const formatUserFilters = (userFiltersState: PickUserFilterType[] | undefined, pathKey: KeyOfPages, parseUserFilterToCommonFilterType: ReturnType<typeof useParseUserFilterToCommonFilterType>) => userFiltersState?.map((userFilt) => ({ ...userFilt, commonFilter: parseUserFilterToCommonFilterType(userFilt.filters, userFilt.json, pathKey, userFilt.lastModifiedPackageTypeEnum) }
))?.sort((a, b) => (a.order || 0) - (b.order || 0))

export function UserFiltersProvider(props: UserFiltersProviderProps) {
  const { children } = props;
  const user = useUser();
  const pathKey = useGetPathKey();
  const pathKeyRef = useRef<KeyOfPages>();
  const [error, setError] = useState<Error | null>(null);
  const [userFiltersState, setUserFilters] = useState<PickUserFilterType[]>()
  const [userFilterFetched, setUserFilterFetched] = useState<boolean>(false)
  const [openWarningFilter, setOpenWarningFilter] = useState(false);
  const parseUserFilterToCommonFilterType = useParseUserFilterToCommonFilterType();

  const [setFilterProfileUrl, deleteFilterProfileUrl] = useUserSettingsContextSelector((ctx) => [ctx.setFilterProfileUrl, ctx.deleteFilterProfileUrl])

  const userFilters: UserFiltersType[] | undefined = useMemo(() => formatUserFilters(userFiltersState, pathKey, parseUserFilterToCommonFilterType), [pathKey, userFiltersState, parseUserFilterToCommonFilterType]);
  // Before we were using name to get the selected filter but now we have ID we could use that
  const defaultFilterId = useMemo(() => userFilters?.find((filt) => filt.isDefault)?.id, [userFilters]);

  const [selectedFilterId, setSelectedFilterIdState] = useState(defaultFilterId);

  const isCurrFilterHasRestriction = useSelectedFilterHasRestrictions();

  // Fetching User Filter From Query and state 
  const [fetchUserFiltersQuery, { loading: userFilterLoading }] = useLazyQuery<GetUserFilters, GetUserFiltersVariables>(GET_USER_FILTERS);
  const fetchUserFilters = useCallback((newPathKey: KeyOfPages): Promise<UserFiltersType[] | undefined> => new Promise((resolve) => {

    const toolEnum = mapPathKeyToToolEnum(newPathKey);
    Sentry.addBreadcrumb({
      category: "info",
      message: `Fetching user Filters`,
      data: { newPathKey },
      level: "log",
    });
    if (user.isLoggedIn) {
      fetchUserFiltersQuery({
        variables: { toolEnum }
      }).then((newFilterData) => {
        const userFilterData = newFilterData.data?.user.filter.filters;
        setUserFilters(userFilterData);
        if (userFilterData) {
          setSelectedFilterIdState(userFilterData?.find((filt) => filt.isDefault)?.id || userFilterData?.[0]?.id);
          // Migrate Filter for old User
          // checkFilterAndMigrate(parsedFilter);
        }
        setUserFilterFetched(true);
        Sentry.addBreadcrumb({
          category: "info",
          message: `User Filters`,
          data: { userFilters: userFilterData },
          level: "log",
        });
        resolve(formatUserFilters(userFilterData, newPathKey, parseUserFilterToCommonFilterType));
      }).catch((err) => {
        setError(err);
        Sentry.withScope((scope) => {
          scope.setExtra("userId", user.data?.userId);
          scope.setExtra("email", user.data?.emailAddress);
          scope.setExtra("toolEnum", toolEnum);
          scope.setExtra("Error", err);
          Sentry.captureException(
            new Error(
              `Custom Error - Error Fetching User Filter`,
            ),
          );
        });
      });
    } else {
      const userFilState = loadState<PickUserFilterType[]>(KEY) || [];
      const toolEnumUserFilter = userFilState.filter((filt) => filt.toolEnum === toolEnum);
      if (toolEnumUserFilter) {
        setUserFilters(toolEnumUserFilter);
        setSelectedFilterIdState(toolEnumUserFilter?.find((filt) => filt.isDefault)?.id || toolEnumUserFilter?.[0]?.id);
        // Migrate Filter for old User
        // checkFilterAndMigrate(parsedFilter);
      }
      setUserFilterFetched(true);
      resolve(formatUserFilters(toolEnumUserFilter, newPathKey, parseUserFilterToCommonFilterType));
    }
  }), [user.isLoggedIn, user.data?.userId, user.data?.emailAddress, fetchUserFiltersQuery, parseUserFilterToCommonFilterType]);

  const setSelectedFilterId = useCallback((filterId: string | undefined) => {
    const selectedFilter = userFilters?.find((filt) => filt.id === filterId || defaultFilterId);
    if (selectedFilter && isCurrFilterHasRestriction(selectedFilter.commonFilter)) {
      setOpenWarningFilter(true);
    }
    if (selectedFilter) setSelectedFilterIdState(filterId);
  }, [defaultFilterId, isCurrFilterHasRestriction, userFilters]);

  // Initial Call to getFilters
  useMemo(() => {
    if (pathKeyRef.current !== pathKey) {
      fetchUserFilters(pathKey);
      pathKeyRef.current = pathKey;
    }
  }, [fetchUserFilters, pathKey]);

  const updatePageChange = useCallback(() => {
    setUserFilters(undefined);
    fetchUserFilters(pathKey);
  }, [fetchUserFilters, pathKey])

  //  Callbacks and method to add user filter in query and in local storage
  const [handleAddUserFilter] = useMutation<AddUserFilter, AddUserFilterVariables>(ADD_USER_FILTER, {});
  const addUserFilterQuery = useCallback(
    (filter: InputCreatePickUserFilterType) => handleAddUserFilter({ variables: { filter } }),
    [handleAddUserFilter]
  );
  const addUserFilterState = useCallback(
    (filter: InputCreatePickUserFilterType) => {
      const prevFilters = loadState<PickUserFilterType[]>(KEY) || [];
      const addData = {
        ...filter,
        id: uuidv4(),
        createdDateTime: Date.now(),
        lastModifiedDateTime: Date.now(),
      };
      prevFilters.push(addData);
      saveState(KEY, prevFilters);
      return addData;
    },
    []
  );
  const addUserFilter = useCallback((filter: InputCreatePickUserFilterType, commonFilterType: CommonFilterType, setSelected?: boolean) => {
    const newFilt = filter;
    if (newFilt.order === undefined) {
      newFilt.order = (userFilters?.length && userFilters[userFilters.length - 1]?.order !== undefined) ? userFilters[userFilters.length - 1].order! + 1 : 0;
    }
    if (user.isLoggedIn) {
      addUserFilterQuery(newFilt).then((data) => {
        const filterReturnData = data.data?.user.filters.add;
        if (filterReturnData) {
          // Save Filter In current state
          // setUserFilters((prev) => {
          //   if (!prev) return [filterReturnData];
          //   return prev?.concat(filterReturnData);
          // });
          if (setSelected) setSelectedFilterId(filterReturnData.id);
        }
      })
        .catch((err) => {
          Sentry.addBreadcrumb({
            category: "info",
            message: `Edit filter for user`,
            data: { newFilter: newFilt, commonFilterType },
            level: "log",
          });
          setError(err);
          Sentry.withScope((scope) => {
            scope.setExtra("userId", user.data?.userId);
            scope.setExtra("email", user.data?.emailAddress);
            scope.setExtra("Filter", JSON.stringify(filter));
            scope.setExtra("Error", err);
            Sentry.captureException(
              new Error(
                `Custom Error - Error Adding User Filter`,
              ),
            );
          });
        });;
    } else {
      const data = addUserFilterState({ ...newFilt });
      // Save Filter In current state
      setUserFilters((prev) => {
        if (!prev) return [data];
        return prev?.concat(data);
      });
      if (setSelected) setSelectedFilterId(data.id);
    }

    // TODO:
    // For Now Storing the filter data in the old user setting object also, but remove this after some time
    setFilterProfileUrl({ key: pathKey, groupName: filter.name, data: commonFilterType, color: filter.color, filterIcon: filter.icon });
  }, [addUserFilterQuery, addUserFilterState, pathKey, setFilterProfileUrl, setSelectedFilterId, user.data?.emailAddress, user.data?.userId, user.isLoggedIn, userFilters]);

  //  Callbacks and method to Edit user filter in query and in local storage
  const [handleEditUserFilter] = useMutation<EditUserFilters, EditUserFiltersVariables>(EDIT_USER_FILTERS, {});
  const editUserFilterQuery = useCallback(
    (filters: InputEditPickUserFilterType[]) => handleEditUserFilter({ variables: { filters } }),
    [handleEditUserFilter]
  );

  const editUserFilterState = useCallback(
    (filters: InputEditPickUserFilterType[]) => {
      const prevFilters = loadState<PickUserFilterType[]>(KEY) || [];
      const newFilters = prevFilters.map((prevFilt) => {
        const filter = filters.find((filt) => filt.id === prevFilt.id);
        if (filter) {
          return {
            ...filter,
            lastModifiedDateTime: Date.now(),
          };
        }
        return prevFilt;
      });
      saveState(KEY, newFilters);
      return newFilters;
    },
    []
  );
  const editUserFilter = useCallback((filters: InputEditPickUserFilterType[], toolEnum: ToolEnumType) => {
    Sentry.addBreadcrumb({
      category: "info",
      message: `Edit filter for user`,
      data: { filters },
      level: "log",
    });
    const editUserFilterVar = filters.map((filt) => ({
      id: filt.id,
      name: filt.name,
      toolEnum: filt.toolEnum,
      icon: filt.icon,
      color: filt.color,
      json: filt.json,
      filters: filt.filters,
      isDefault: filt.isDefault,
      order: filt.order,
    }));
    if (user.isLoggedIn) {
      editUserFilterQuery(editUserFilterVar)
        .then((data) => {
          const editFilterData = data.data?.user.filters.edits;
          if (editFilterData) {
            // Save Filter In current state
            // setUserFilters((prev) => {
            //   // If the edit filter is of old page and the filter is already changed for new page, don't save it
            //   if (prev?.[0].toolEnum !== toolEnum) return prev;
            //   const editFilterMap = editFilterData.reduce<Record<string, PickUserFilterType>>((acc, curr) => {
            //     acc[curr.id] = curr;
            //     return acc;
            //   }, {});
            //   return prev?.map((prevFil) => prevFil.id in editFilterMap ? editFilterMap[prevFil.id] : prevFil);
            // })
          }
        })
        .catch((err) => {
          Sentry.addBreadcrumb({
            category: "info",
            message: `Edit filter for user`,
            data: { newFilter: editUserFilterVar },
            level: "log",
          });
          setError(err);
          Sentry.withScope((scope) => {
            scope.setExtra("userId", user.data?.userId);
            scope.setExtra("email", user.data?.emailAddress);
            scope.setExtra("Error", err);
            scope.setExtra("Error Message", err.message);
            scope.setExtra("Filter", JSON.stringify(editUserFilterVar));
            Sentry.captureException(
              new Error(
                `Custom Error - Error Editing User Filter`,
              ),
            );
          });
        });
    } else {
      const newFilters = editUserFilterState(editUserFilterVar);
      // Save Filter In current state with current tool enum
      if (newFilters && toolEnum) {
        const toolEnumUserFilter = newFilters.filter((filt) => filt.toolEnum === toolEnum);
        // If the edit filter is of old page and the filter is already changed for new page, don't save it
        setUserFilters((prev) => prev?.[0].toolEnum !== toolEnum ? prev : toolEnumUserFilter);
      }
    }

    // TODO:
    // For Now Editing the filter data in the old user setting object also, but remove this after some time
    // filters.forEach((filter) => {
    //   updateFilterProfileUrl({ key: pathKey, data: {name: filter.name, data: par(filter.json, pathKey), color: filter.color, filterIcon: filter.icon }});
    // });
  }, [editUserFilterQuery, editUserFilterState, user.data?.emailAddress, user.data?.userId, user.isLoggedIn]);
  const editUserFilterDebounced = useMemo(() => debounce(editUserFilter, 1000), [editUserFilter]);

  //  Callbacks and method to remove user filter in query and in local storage
  const [handleRemoveUserFilter] = useMutation<RemoveUserFilter, RemoveUserFilterVariables>(REMOVE_USER_FILTER, {});
  const removeUserFilterQuery = useCallback(
    (filterId: string) => {
      handleRemoveUserFilter({ variables: { id: filterId } });
    },
    [handleRemoveUserFilter]
  );
  const removeUserFilterState = useCallback(
    (filterId: string) => {
      const prevFilters = loadState<PickUserFilterType[]>(KEY) || [];
      const newFilters = prevFilters.filter((prevFilt) => prevFilt.id !== filterId);
      saveState(KEY, newFilters);
    },
    []
  );
  const removeUserFilter = useCallback((filterId: string) => {
    const deletedFilterData = userFilters?.find((dat) => dat.id === filterId);
    if (user.isLoggedIn) {
      removeUserFilterQuery(filterId);
    } else {
      removeUserFilterState(filterId);
      setUserFilters((prev) => prev?.filter((prevFilt) => prevFilt.id !== filterId));
    }
    if (filterId === selectedFilterId) {
      setSelectedFilterId(defaultFilterId);
    }
    // TODO:
    // For Now Deleting the filter data in the old user setting object also, but remove this after some time
    if (deletedFilterData?.name) deleteFilterProfileUrl({ key: pathKey, groupName: deletedFilterData.name });
  }, [defaultFilterId, deleteFilterProfileUrl, pathKey, removeUserFilterQuery, removeUserFilterState, selectedFilterId, setSelectedFilterId, user.isLoggedIn, userFilters]);
  const removeUserFilterDebounced = useMemo(() => debounce((removeUserFilter), 1000), [removeUserFilter]);

  const setDefaultFilterId = useCallback(() => {
    setSelectedFilterId(userFilters?.find((filt) => filt.isDefault)?.id);
  }, [setSelectedFilterId, userFilters]);

  const resetFilterToDefault = useCallback((getApiVariablesFromFilters: GetApiVariablesFromFiltersType, filterId?: string) => {
    const selectedFilter = userFilters?.find((filt) => filt.id === (filterId || selectedFilterId)) || userFilters?.find((filt) => filt.isDefault);
    if (!selectedFilter) return;
    const selectedPathKey = mapToolEnumKeyToPathKey(selectedFilter.toolEnum);
    const defaultFilterValues = getDefaultFilterValuesByPathKey(mapToolEnumKeyToPathKey(selectedFilter.toolEnum));
    if (selectedFilter) {
      const filtersQueryData = getApiVariablesFromFilters(selectedPathKey, selectedPathKey, defaultFilterValues).requestVariableForPage;
      editUserFilterDebounced([{ ...selectedFilter, json: JSON.stringify(null), filters: filtersQueryData }], mapPathKeyToToolEnum(pathKey));
    }
  }, [editUserFilterDebounced, userFilters, selectedFilterId, pathKey]);

  const onCloseErrorPopup = () => {
    setError(null);
  };

  const providerValue: UserFiltersContextValue = useMemo(
    () => ({
      userFilterFetched,
      addUserFilter,
      editUserFilter: editUserFilterDebounced,
      removeUserFilter: removeUserFilterDebounced,
      updatePageChange,
      userFilterLoading,
      userFilters,
      selectedFilterId,
      setSelectedFilterId,
      setDefaultFilterId,
      resetFilterToDefault,
      defaultFilterId,
      fetchUserFilters,
      setUserFilters,
    }),
    [userFilterFetched, addUserFilter, editUserFilterDebounced, removeUserFilterDebounced, updatePageChange, userFilterLoading, userFilters, selectedFilterId, setSelectedFilterId, setDefaultFilterId, resetFilterToDefault, defaultFilterId, fetchUserFilters, setUserFilters],
  );

  return (
    <>
      <UserFiltersContext.Provider value={providerValue}>{children}</UserFiltersContext.Provider>
      <SelectedFilterRestrictionWarningModel openWarningFilter={openWarningFilter} setOpenWarningFilter={setOpenWarningFilter} />
      {error && <ErrorBoundaryPopup message='Error Saving User Filter. Please Try again. If problem If the problem persists, please contact us at support@picktheodds.app' onClose={onCloseErrorPopup} />}
    </>
  );
}
