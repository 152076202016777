import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function BookMarkIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={19} height={24} viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.155 24q-0.922 0 -1.538 -0.616Q0.002 22.768 0 21.848V2.153Q0 1.233 0.617 0.617 1.235 0.002 2.155 0h14.358q0.92 0 1.538 0.617 0.617 0.618 0.616 1.538v19.692q0 0.92 -0.616 1.537 -0.617 0.618 -1.539 0.616zm0 -1.333h14.358q0.307 0 0.564 -0.256 0.258 -0.257 0.256 -0.566V2.155q0 -0.309 -0.256 -0.566t-0.565 -0.256h-1.845v8.155l-2.666 -1.59 -2.667 1.59v-8.154H2.155q-0.309 0 -0.566 0.256t-0.256 0.564v19.694q0 0.306 0.256 0.564t0.564 0.256" />
    </SvgIcon>
  );
}

export default BookMarkIcon;
