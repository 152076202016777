import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function OddTypeIcon(props: SvgIconProps) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" {...props}>
            <path
                fill="currentColor"
                d="m208.49 64.49l-144 144a12 12 0 0 1-17-17l144-144a12 12 0 0 1 17 17M60 112a12 12 0 0 0 24 0V84h28a12 12 0 0 0 0-24H84V32a12 12 0 0 0-24 0v28H32a12 12 0 0 0 0 24h28Zm164 60h-80a12 12 0 0 0 0 24h80a12 12 0 0 0 0-24"
            />
        </SvgIcon>
    );
}

export default OddTypeIcon;
