import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { useGetUserFilterValue } from "@/hooks/useGetUserFilterValue";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import useGetPathKey from "@/hooks/useGetPathKey";
import { generateRandomUintNumber } from "@/utis/generateRandomUintNumber";
import { useUserFiltersContextSelector } from "@/user/user-filters/UserFiltersContext";
import { convertEvFormulaWeightQueryToApiVariable, convertEvWeightQueryToApiVariable } from "@/components/ExpectedValue/helper";
import { FilterEnum } from "./FilterList";
import { CommonFilterType, getDefaultFilterValuesByPathKey, KeyOfPages } from "./commonFilterType";
import { AdvanceFilterType, UserAdvanceFilterArrayType, UserAdvanceFilterType } from "./FilterContext.common";

export type FilterContextValue = {
  filterState: CommonFilterType;
  setFilter: (val: Partial<CommonFilterType>) => void;
  setAllFilter: (val: CommonFilterType) => void;
  resetCurrentFilter: () => void;
  setDefaultFilter: () => void;
  isFilterContainerOpen: boolean;
  setIsFilterContainerOpen: (val: boolean) => void;
  selectedFilter: FilterEnum | undefined;
  setSelectedFilter: (val: FilterEnum | undefined) => void;
  advanceFilter: AdvanceFilterType;
  setAdvanceFilter: (val: AdvanceFilterType | undefined) => void;
  userAdvanceFilter: UserAdvanceFilterArrayType;
  queryStatePathKey: KeyOfPages;
  getAdvanceFilterState: (filterKey: string) => Omit<Partial<CommonFilterType>, "advanceFilter"> | undefined;
  getAdvanceFilter: (advanceFilterProp?: string) => UserAdvanceFilterType[] | undefined
};

const FilterContext = createContext<FilterContextValue>(undefined!);
export default FilterContext;

export function useFilterContextSelector<Selected>(selector: (value: FilterContextValue) => Selected) {
  return useContextSelector(FilterContext, selector);
}

export function parseEVWeightsInAdvanceFilters(filter: Omit<Partial<CommonFilterType>, "advanceFilter">) {
  const newFilter = filter;
  if (newFilter.evWeights) {
    newFilter.evWeights = convertEvWeightQueryToApiVariable(newFilter.evWeights);
  }
  if (newFilter.evWeights) {
    newFilter.evFormulaWeight = convertEvFormulaWeightQueryToApiVariable(newFilter.evFormulaWeight);
  }
  return newFilter;
}

const parseAdvanceFilter = (advanceFilter: string) => (JSON.parse(advanceFilter) as UserAdvanceFilterArrayType)?.map((advFil) => {
  const mappedFilter = advFil;
  if (!mappedFilter.requestHash) {
    mappedFilter.requestHash = generateRandomUintNumber();
  }

  // We have removed the league as tennis so to handle advance filter for that we shift the advance filter for sports of tennis
  if (mappedFilter.filterEnum === FilterEnum.LEAGUES && mappedFilter.filterKey === "TENNIS") {
    mappedFilter.filterEnum = FilterEnum.SPORTS;
  }
  if (mappedFilter.filterEnum === FilterEnum.LEAGUES_RESTRICTED && mappedFilter.filterKey === "TENNIS") {
    mappedFilter.filterEnum = FilterEnum.SPORTS_RESTRICTED;
  }

  mappedFilter.filters = parseEVWeightsInAdvanceFilters(mappedFilter.filters);
  return mappedFilter;
})

type FilterProviderProps = {
  children: React.ReactNode;
};

export function FilterProvider({ children }: FilterProviderProps) {

  const pathKey = useGetPathKey();
  const isCollapsed = useUserSettingsContextSelector((ctx) => ctx.globalSettings.isCollapsed);
  const setIsCollapsed = useUserSettingsContextSelector((ctx) => ctx.setIsCollapsed);
  const getUserFilterValues = useGetUserFilterValue();
  const [queryStatePathKey, setQueryStatePathKey] = useState(pathKey);
  const [isFilterContainerOpen, setIsFilterContainerOpenState] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterEnum>();
  const [advanceFilter, setAdvanceFilter] = useState<AdvanceFilterType>();
  const [selectedFilterId, defaultFilterId, fetchUserFilters] = useUserFiltersContextSelector((ctx) => [ctx.selectedFilterId, ctx.defaultFilterId, ctx.fetchUserFilters]);
  const [userFilters, userFilterLoading] = useUserFiltersContextSelector((ctx) => [ctx.userFilters, ctx.userFilterLoading]);
  const [filterState, setFilterState] = useState<CommonFilterType | undefined>();

  // useEffect(() => {
  //   if (isLoggedIn && !isFilterMigratedV3) return
  //   const noFiltersExist = !userFilters?.length;
  //   if (noFiltersExist && !userFilterLoading) {
  //     dispatch(filtersAction.ensureDefaultFilter({ pathKey, getApiVariablesFromFilters }));
  //   }
  // }, [userFilters, userFilterLoading, pathKey, dispatch, isLoggedIn, isFilterMigratedV3, getApiVariablesFromFilters]);

  const setFilter = useCallback(
    (val: Partial<CommonFilterType>) => {
      setFilterState({ ...(filterState || getDefaultFilterValuesByPathKey(pathKey)), ...val });
    },
    [filterState, pathKey],
  );

  const setAllFilter = useCallback((val: CommonFilterType) => {
    setFilterState(val);
  }, []);

  const setIsFilterContainerOpen = useCallback(
    (val: boolean) => {
      setIsFilterContainerOpenState(val);
      if (val === false) {
        setIsCollapsed(isCollapsed);
      }
    },
    [isCollapsed, setIsCollapsed, setIsFilterContainerOpenState],
  );

  const resetCurrentFilter = useCallback(() => {
    setFilterState(getDefaultFilterValuesByPathKey(pathKey));
  }, [pathKey]);

  const setDefaultFilter = useCallback(() => {
    setFilterState(getUserFilterValues(userFilters, selectedFilterId, defaultFilterId));
  }, [defaultFilterId, getUserFilterValues, selectedFilterId, userFilters]);

  useEffect(() => {
    if (!userFilterLoading && filterState === undefined && userFilters?.length && selectedFilterId) {
      setFilterState(getUserFilterValues(userFilters, selectedFilterId, defaultFilterId));
    }
  }, [defaultFilterId, filterState, getUserFilterValues, selectedFilterId, userFilterLoading, userFilters]);

  useEffect(() => {
    if (pathKey !== queryStatePathKey && !userFilterLoading) {
      fetchUserFilters(pathKey).then((userFiltersData) => {
        const newDefaultFilterId = userFiltersData?.find((filt) => filt.isDefault)?.id;
        setQueryStatePathKey(pathKey);
        setFilterState(getUserFilterValues(userFiltersData, undefined, newDefaultFilterId));
      })
    }
  }, [fetchUserFilters, getUserFilterValues, pathKey, queryStatePathKey, userFilterLoading]);

  const userAdvanceFilter = useMemo(() => {
    if (!filterState?.advanceFilter) return undefined;
    return parseAdvanceFilter(filterState?.advanceFilter);
  }, [filterState?.advanceFilter]);

  const getAdvanceFilter = useCallback((advanceFilterProp?: string) => {
    if (!advanceFilterProp) return undefined;
    return parseAdvanceFilter(advanceFilterProp);
  }, []);

  const getAdvanceFilterState = useCallback(
    (filterKey: string) => userAdvanceFilter?.find((uesrAdvFil) => uesrAdvFil.filterKey === filterKey)?.filters,
    [userAdvanceFilter],
  );

  const providerValue: FilterContextValue = useMemo(
    () => ({
      filterState: filterState || getDefaultFilterValuesByPathKey(pathKey),
      setFilter,
      setAllFilter,
      resetCurrentFilter,
      setDefaultFilter,
      isFilterContainerOpen,
      setIsFilterContainerOpen,
      selectedFilter,
      setSelectedFilter,
      advanceFilter,
      setAdvanceFilter,
      userAdvanceFilter,
      queryStatePathKey,
      getAdvanceFilterState,
      getAdvanceFilter,
    }),
    [
      filterState,
      setFilter,
      setAllFilter,
      resetCurrentFilter,
      setDefaultFilter,
      isFilterContainerOpen,
      setIsFilterContainerOpen,
      selectedFilter,
      advanceFilter,
      userAdvanceFilter,
      queryStatePathKey,
      getAdvanceFilterState,
      getAdvanceFilter,
      pathKey,
    ],
  );

  return <FilterContext.Provider value={providerValue}>{children}</FilterContext.Provider>;
}