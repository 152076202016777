import { League } from '@/components/Common/Enums/LeagueEnum';
import { Sports } from '@/components/Common/Enums/SportsEnum';
import { KeyOfPages } from '@/components/Common/Filters/commonFilterType';
import { parseUserFilterToCommonFilterType } from '@/helper/parseUserFilterToCommonFilterType';
import { InputFilterBaseRequestType } from '@/user/user-filters/InputFilterBaseRequestType';
import { useUserSettingsContextSelector } from '@/user/user-settings/UserSettingsContext';
import { useUser } from '@/user/UserContext'
import { PackageTypeEnum } from '@/user/common';
import { PartialRecord } from '@/utis/partialRecord';
import { useCallback, useMemo } from 'react'
import useGetConstants from './useGetConstants';

function useParseUserFilterToCommonFilterType() {
  const { data: userData } = useUser();
  const globalSportsBookEnums = useUserSettingsContextSelector((ctx) => ctx.globalSettings.sportsBookEnums);
  const sports = useGetConstants().data?.sports;

  const sportsLeagueMap = useMemo(() => sports?.reduce<PartialRecord<Sports, League[]>>((acc, curr) => {
    if (!(curr.sportEnum in acc)) acc[curr.sportEnum] = []
    curr.leagues.forEach(league => {
      acc[curr.sportEnum]?.push(league.leagueEnum);

    });
    return acc;
  }, {}), [sports])

  return useCallback((
    filters: InputFilterBaseRequestType[] | undefined,
    json: string | undefined,
    pathKey: KeyOfPages,
    lastModifiedPackageTypeEnum?: PackageTypeEnum,
  ) => parseUserFilterToCommonFilterType(filters, json, pathKey, userData?.activePackageType, globalSportsBookEnums, sportsLeagueMap), [globalSportsBookEnums, sportsLeagueMap, userData?.activePackageType])
}

export default useParseUserFilterToCommonFilterType